import { FC, memo } from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import { MainDateAndAddress } from '@/features/MainDateAndAddress';

const mainAuto = require('assets/images/landing/mainAuto.png');

import { LocaleType } from '@/types';
import { useErrors } from '@/utils/useErrors';

type Props = {
  locale: LocaleType;
};

export const MainBlock: FC<Props> = memo(({ locale }) => {
  const { t } = useTranslation();
  const { errors, setErrors } = useErrors();

  return (
    <div className="relative">
      <div
        className="hidden md:block absolute top-0 bottom-0 left-1/2 w-1/2"
        style={{
          backgroundImage: `url(${mainAuto})`,
        }}>
        <Image
          fill
          placeholder="blur"
          sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
          className="object-center object-cover pointer-events-none"
          src={mainAuto}
          alt={`$t('mainPage.title1')} ${t('mainPage.title2')}`}
        />
      </div>

      <div
        className="container relative mx-auto px-6 sm:px-0 pb-6 pt-24
      md:pb-20 md:pt-32 lg:pb-24 lg:pt-56 xl:pb-28 xl:pt-72 z-10">
        <h1 className="landing-header">
          {t('mainPage.title1')}
          <br />
          {t('mainPage.title2')}
        </h1>
        <MainDateAndAddress locale={locale} errors={errors} setErrors={setErrors} />
      </div>
    </div>
  );
});
