import Image from 'next/image';
import { useTranslation } from 'next-i18next';

const ImageMap = require('assets/images/landing/map.png');
const IconBubbles = require('assets/images/landing/iconBubbles.png');
const IconFolder = require('assets/images/landing/iconFolder.png');

export const HowCarRental = () => {
  const { t } = useTranslation();

  const carRentalCards = [
    {
      image: IconBubbles,
      title: `${t('mainPage.carRentalCard1')}`,
    },
    {
      image: IconFolder,
      title: `${t('mainPage.carRentalCard2')}`,
    },
    {
      image: IconBubbles,
      title: `${t('mainPage.carRentalCard3')}`,
    },
    {
      image: IconFolder,
      title: `${t('mainPage.carRentalCard4')}`,
    },
    {
      image: IconBubbles,
      title: `${t('mainPage.carRentalCard5')}`,
    },
    {
      image: IconFolder,
      title: `${t('mainPage.carRentalCard6')}`,
    },
    {
      image: IconBubbles,
      title: `${t('mainPage.carRentalCard7')}`,
    },
    {
      image: IconFolder,
      title: `${t('mainPage.carRentalCard8')}`,
    },
  ];

  const cards = carRentalCards.map(({ image, title }, i) => (
    <div className="bg-white p-6 flex flex-col justify-between shadow-lg" key={i}>
      <div className="self-end mb-12">
        <Image placeholder="blur" src={image} alt={title} />
      </div>
      <p className="font-medium">{title}</p>
    </div>
  ));

  return (
    <div className="background-block-color py-10 px-6 lg:py-24">
      <div className="container mx-auto">
        <h2 className="landing-header-small mb-12 md:pr-20">{t('mainPage.headerCarRental')}</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-x-4 lg:gap-y-10 mb-10 lg:mb-20">{cards}</div>
        <div className="grid md:grid-cols-2 gap-6 lg:gap-16">
          <div>
            <Image placeholder="blur" src={ImageMap} alt={t('mainPage.headerCarRental')} />
          </div>
          <div className="self-center">
            <h2 className="landing-header-small mb-8 lg:w-2/3"> {t('mainPage.headerGetYourCar')} </h2>
            <p className="mb-6 ml-20 lg:ml-40 font-medium"> {t('mainPage.titleGetYourCar')} </p>
          </div>
        </div>
      </div>
    </div>
  );
};
