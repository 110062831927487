import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { Route } from '@/constants/routes';

const RentArrowIcon = require('assets/icons/rent-arrow-icon.svg');
const Delivered = require('assets/images/landing/delivered.png');

export const DiscoverMore = () => {
  const { t } = useTranslation();

  return (
    <div className="background-block-color py-10 px-6 lg:py-24">
      <div className="container mx-auto">
        <div className="w-full md:w-3/4 lg:w-2/3">
          <h2 className="landing-header-small mb-8 md:pr-20"> {t('mainPage.headerCarDelivered')}</h2>
          <div className="ml-20 lg:ml-40">
            <p className="font-medium mb-6">{t('mainPage.titleCarDelivered')}</p>
            <div className="h-0.5 w-1/12 bg-gray-400" />
            <div className="flex items-center mb-10 lg:mb-20">
              <p className="mr-4 font-light">{t('mainPage.discoverMore.title')}</p>
              <Link
                prefetch={false}
                href={Route.BookingSearchCar}
                className="bg-white shadow-lg h-12 w-12 rounded-full flex-center transition hover:bg-blue-50">
                <Image src={RentArrowIcon} alt={t('mainPage.discoverMore.title')} className="primary-color-text" />
              </Link>
            </div>
            <div>
              <Image placeholder="blur" src={Delivered} alt={t('mainPage.discoverMore.delivered')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
