import { dehydrate, QueryClient } from 'react-query';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextSeo } from 'next-seo';

import { api } from '@/api';
import { QueryKey } from '@/constants/api';
import { DEFAULT_LOCALE, LOCALES } from '@/constants/global';
import { GoogleMapWrapper } from '@/features/GoogleMapWrapper';
import { LocaleType } from '@/types';
import { getServerCookies } from '@/utils/getServerCookies';
import { BusinessOffer } from '@/widgets/Home/BusinessOffer';
import { Cars } from '@/widgets/Home/Cars';
import { Customers } from '@/widgets/Home/Customers';
import { DiscoverMore } from '@/widgets/Home/DiscoverMore';
import { HowCarRental } from '@/widgets/Home/HowCarRental';
import { InfoBlock } from '@/widgets/Home/InfoBlock';
import { MainBlock } from '@/widgets/Home/MainBlock';
import { StepsOfApp } from '@/widgets/Home/StepsOfApp';
import { UnblockCar } from '@/widgets/Home/UnblockCar';

const Home = () => {
  const router = useRouter();
  const { t } = useTranslation();

  const locale = router.locale as LocaleType;

  return (
    <GoogleMapWrapper>
      <NextSeo
        title={t('mainPage.pageTitle')}
        description={t('mainPage.pageDescription')}
        openGraph={{
          title: t('mainPage.pageTitle'),
          description: t('mainPage.pageDescription'),
        }}
        additionalMetaTags={[
          {
            property: 'keywords',
            content: t('mainPage.keywords'),
          },
        ]}
      />
      <MainBlock locale={locale} />
      <InfoBlock />
      <Cars locale={locale} />
      <HowCarRental />
      <StepsOfApp />
      <DiscoverMore />
      <UnblockCar />
      <Customers />
      <BusinessOffer />
    </GoogleMapWrapper>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { locale } = context;
  const localLocale = (locale || DEFAULT_LOCALE) as LocaleType;
  const queryClient = new QueryClient();

  try {
    await queryClient.fetchQuery([QueryKey.CARS, localLocale], () => api.cars.getCars({ locale: localLocale }));
  } catch (error) {
    console.error('no cars for rent', error);
  }

  try {
    await queryClient.fetchQuery([QueryKey.CAR_FILTERS, localLocale], () => api.cars.getCarFilters());
  } catch (error) {
    console.error('no cars filter', error);
  }

  return {
    props: {
      languageMarkup: {
        awailableLocales: LOCALES,
        defaultLocale: DEFAULT_LOCALE,
      },
      ...(await serverSideTranslations(locale || DEFAULT_LOCALE, ['default'])),
      dehydratedState: dehydrate(queryClient),
      ...getServerCookies(context),
    },
  };
};

export default Home;
