import Image from 'next/image';
import { useTranslation } from 'next-i18next';

const Call1 = require('assets/images/landing/call1.png');
const Call2 = require('assets/images/landing/call2.png');

export const UnblockCar = () => {
  const { t } = useTranslation();

  return (
    <div className="py-10 px-6 lg:py-24">
      <div className="container mx-auto">
        <div className="w-full md:w-3/4 lg:w-2/3">
          <h2 className="landing-header-small mb-8 md:pr-20">{t('mainPage.headerRentCarAndUnlock')}</h2>
          <div className="ml-20 lg:ml-40 mb-10 lg:mb-14">
            <p className="mb-6 font-medium">{t('mainPage.titleRentCarAndUnlock')}</p>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-8">
          <div className="grid md:grid-cols-2 gap-6 lg:gap-16">
            <div>
              <Image placeholder="blur" src={Call1} alt={t('mainPage.headTheKeyToBetterRental1')} />
            </div>
            <div className="self-center">
              <h3 className="text-2xl md:text-3xl font-bold mb-6 w-3/4">{t('mainPage.headTheKeyToBetterRental1')}</h3>
              <p className="font-light leading-6 w-11/12">{t('mainPage.titleTheKeyToBetterRental1')}</p>
            </div>
          </div>
          <div className="grid md:grid-cols-2 gap-6 lg:gap-16">
            <div className="self-center order-last md:order-none">
              <h3 className="text-2xl md:text-3xl font-bold mb-6 w-3/4">{t('mainPage.headTheKeyToBetterRental2')}</h3>
              <p className="font-light leading-6 w-11/12">{t('mainPage.titleTheKeyToBetterRental2')}</p>
            </div>
            <div>
              <Image placeholder="blur" src={Call2} alt={t('mainPage.headTheKeyToBetterRental2')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
