import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import { Route } from '@/constants/routes';

const Step1app = require('assets/images/landing/step1app.png');
const Step2app = require('assets/images/landing/step2app.png');
const Step3app = require('assets/images/landing/step3app.png');
const AppleStore = require('assets/icons/appleStore.svg');
const GooglePlay = require('assets/icons/googlePlay.svg');
const DownloadImage = require('assets/images/landing/download.png');

export const StepsOfApp = () => {
  const { t } = useTranslation();

  return (
    <div className="px-6 sm:px-0 py-10 lg:py-24 container mx-auto">
      <div className="grid md:grid-cols-3 gap-10 mb-10 lg:mb-24">
        <div>
          <p className="text-blue-500 my-5">01</p>
          <p className="font-bold text-2xl md:mb-5">{t('mainPage.headDownloadApp1')}</p>
          <p className="font-light">{t('mainPage.titleDownloadApp1')}</p>
          <div className="mt-5 md:mt-10">
            <Image placeholder="blur" src={Step1app} alt={t('mainPage.headDownloadApp1')} />
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-col">
          <div className="mt-5 md:mt-0 md:mb-10">
            <Image placeholder="blur" src={Step2app} alt={t('mainPage.headDownloadApp2')} />
          </div>
          <div>
            <p className="font-bold text-2xl">{t('mainPage.headDownloadApp2')}</p>
            <p className="font-light md:mt-5">{t('mainPage.titleDownloadApp2')}</p>
          </div>
          <p className="text-blue-500 mb-5 md:mb-0 md:my-5">02</p>
        </div>
        <div>
          <p className="text-blue-500 my-5">03</p>
          <p className="font-bold text-2xl md:mb-5">{t('mainPage.headDownloadApp3')}</p>
          <p className="font-light">{t('mainPage.titleDownloadApp3')}</p>
          <div className="mt-5 md:mt-10">
            <Image src={Step3app} alt={t('mainPage.headDownloadApp3')} />
          </div>
        </div>
      </div>
      <div className="relative h-40 lg:h-56 bg-cover md:bg-auto bg-center bg-no-repeat flex items-center">
        <Image fill src={DownloadImage} alt={t('mainPage.headDownloadApp1')} />
        <div className="flex flex-col md:flex-row gap-4 ml-10 lg:ml-24 z-10">
          <p className="text-3xl md:text-4xl font-bold mr-5">{t('mainPage.headDownloadApp1')}</p>
          <div className="flex gap-4">
            <a href={Route.Home} className="hover:opacity-80 self-center justify-self-center">
              <Image className="w-full" src={AppleStore} alt={t('appleStore')} />
            </a>
            <a href={Route.Home} className="hover:opacity-80 self-center justify-self-center">
              <Image className="w-full" src={GooglePlay} alt={t('googlePlay')} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
